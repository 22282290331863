import React from 'react';
import appRoutes from '../../../../core/routes';
import useModalStore from '../../../../features/modal/useModalStore';
import LabelCardImage2 from '../../../atom/LabelCard/LabelCardImage2';
import useAuth from '../../../../features/auth/useAuth';

const CardGame = ({ game, cardType }) => {
	let darkVibrant = game?.co?.[3];
	const { openModal } = useModalStore((state) => ({ openModal: state.openModal }));

	const { isLoggedIn } = useAuth();

	const onClick = (demo) => {
		if (demo) {
			window.open(`${appRoutes.PLAY}?gpId=${game?.pi}&gpGameId=${game?.gi}&demo=${true}`, '_blank');
		} else {
			if (isLoggedIn) {
				window.open(
					`${appRoutes.PLAY}?gpId=${game?.pi}&gpGameId=${game?.gi}&demo=${false}`,
					'_blank'
				);
			} else {
				openModal('login');
			}
		}
	};

	switch (cardType) {
		case 'loading':
			return (
				<React.Fragment>
					<div className="animate-pulse flex flex-col aspect-[3/4] bg-inherit gap-1">
						<div className="aspect-square bg-slate-500 rounded-lg"></div>

						<div className="flex w-full flex-col items-center justify-center gap-1">
							<div className="w-full h-2 bg-slate-500 rounded-full"></div>
							<div className="w-full h-2 bg-slate-500 rounded-full"></div>
							<div className="w-full h-2 bg-slate-500 rounded-full"></div>
						</div>
					</div>
				</React.Fragment>
			);
		case 'hot':
			return (
				<React.Fragment>
					<div
						className="group relative aspect-[3/4] overflow-hidden hover:bg-opacity-80 transition-all duration-300 ease-in-out cursor-pointer"
						onMouseEnter={(e) => e.stopPropagation()}
						onClick={() => {
							if (!game?.da) {
								onClick(false);
							}
						}}>
						<div className="flex-col aspect-[3/4] bg-inherit">
							<div className="relative flex-1 aspect-square">
								<div className="flex w-full justify-between absolute top-0">
									<LabelCardImage2
										labelName={'trendingLabel'}
										className={'z-10 w-16 object-contain'}
									/>
									<LabelCardImage2 labelName={'newLabel'} className={'z-10 w-8'} />
								</div>
								<img
									id={`${game?.img}`}
									src={game?.img ? game?.img : '/images/games/404.jpeg'}
									alt={game?.gameName}
									className="w-full h-full object-cover"
									loading="lazy"
								/>
							</div>
							<div className="flex flex-col items-center justify-center p-1">
								<p className="flex-1 w-full text-xs text-center break-words line-clamp-2 font-semibold leading-none text-container-color-primary">
									{game?.pn}
								</p>
							</div>
						</div>

						{game?.da && (
							<React.Fragment>
								<div className="aspect-square">
									<div
										className="absolute top-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
										style={{ background: darkVibrant || '#333333' }}>
										<button
											className="w-full h-full text-container-color-primary"
											onClick={() => {
												onClick(false);
											}}>
											Play
										</button>
									</div>

									<div
										className="absolute bottom-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
										style={{ background: darkVibrant || '#333333' }}>
										<button
											className="w-full h-full text-container-color-primary bg-black/50"
											onClick={() => {
												onClick(true);
											}}>
											<span>Demo</span>
										</button>
									</div>
								</div>
							</React.Fragment>
						)}
					</div>
				</React.Fragment>
			);
		default:
			return (
				<React.Fragment>
					<div
						className="group relative aspect-[3/4] overflow-hidden hover:bg-opacity-80 transition-all duration-300 ease-in-out cursor-pointer"
						onMouseEnter={(e) => e.stopPropagation()}
						onClick={() => {
							if (!game?.da) {
								onClick(false);
							}
						}}>
						<div className="flex flex-col aspect-[3/4] bg-inherit">
							<img
								id={`${game?.pi}-${game?.pn}`}
								src={game?.img ? game?.img : '/images/games/404.jpg'}
								alt="Description"
								className="w-full object-cover aspect-square"
								loading="lazy"
							/>
							<div className="flex-1 flex items-center justify-center text-center text-container-color-primary p-1">
								<p className="text-xs break-words font-semibold leading-none line-clamp-2 text-ellipsis">
									{game?.gn}
								</p>
							</div>
						</div>

						{game?.da && (
							<React.Fragment>
								<div className="aspect-square">
									<div
										className="absolute top-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
										style={{ background: darkVibrant || '#333333' }}>
										<button
											className="w-full h-full text-container-color-primary"
											onClick={() => {
												onClick(false);
											}}>
											Play
										</button>
									</div>

									<div
										className="absolute bottom-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
										style={{ background: darkVibrant || '#333333' }}>
										<button
											className="w-full h-full text-container-color-primary bg-black/50"
											onClick={() => {
												onClick(true);
											}}>
											<span>Demo</span>
										</button>
									</div>
								</div>
							</React.Fragment>
						)}
					</div>
				</React.Fragment>
			);
	}
};

export default CardGame;
