import { useTranslation } from 'react-i18next';
import CardGame from '../Card/CardGame';
import TitleSection from '../../atom/Title/TitleSection';
import { MotionComponent } from '../../atom/MotionComponent';
import useGameStore from '@/context/store/useGameStore.store';

const GameRecommendedComponent2 = ({ slideMode, showTitle }) => {
	const { t } = useTranslation();
	const { recommendedGameData } = useGameStore((state) => ({
		recommendedGameData: state.recommendedGameData
	}));

	return (
		<>
			<MotionComponent slideMode={slideMode}>
				<div className="flex flex-col mb-section">
					{showTitle && <TitleSection title={t('text.recommendedGames')} />}
					<div className="flex gap-2 overflow-x-auto overflow-hidden scrollbar-none">
						{recommendedGameData?.map((item, index) => (
							<div className="w-full h-full" key={index}>
								<div className="w-28">
									<CardGame game={item} />
								</div>
							</div>
						))}
					</div>
				</div>
			</MotionComponent>
		</>
	);
};

export default GameRecommendedComponent2;
