import React from 'react';

const LabelCardImage2 = ({ labelName, className, ...props }) => {
	const list = {
		trendingLabel: '/images/label-card/label_trending.png',
		hotLabel: '/images/label-card/label_hot.png',
		newLabel: '/images/label-card/label_new.png'
	};

	const imagePath = list?.[labelName];

	return (
		<React.Fragment>
			<img src={imagePath} alt="" className={className} {...props} />
		</React.Fragment>
	);
};

export default LabelCardImage2;
